/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        function close_faqs(skip_index) {
          $('.faqs .faq').each( function() {
            var faq = $(this);
            var faq_index = faq.index();

            // skip over the index passed in
            if (faq_index !== skip_index) {
              if (faq.hasClass('open')) {
                faq.children('.faq__answer').slideUp();
                faq.removeClass('open');
              }
            }

          });
        }

        $('.faqs .faq').on('click', function() {
          var faq = $(this);
          var faq_index = faq.index();
          // close any other faqs other than the one passed in
          close_faqs(faq_index);

          if (!faq.hasClass('open')) {
            faq.children('.faq__answer').slideDown();
            faq.addClass('open');
          }
          else {
            faq.children('.faq__answer').slideUp();
            faq.removeClass('open');
          }
        });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

        var slider = tns({
          container: '.home-images__wrap',
          items: 1,
          slideBy: 'page',
          lazyload: true,
          nav: false,
          controlsText: ['<img src="/wp-content/themes/diversity-week/dist/images/arrow_left.svg" />', '<img src="/wp-content/themes/diversity-week/dist/images/arrow_right.svg" />']
        });


      }
    },
    'page_template_default': {
      init: function() {
        // JavaScript to be fired on the campaign pages

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
